
<template>
  <div>
    <div class="row gy-2">
      <div class="col-12">
        <h5 class="mb-3">List of Bank Identities (BVN)</h5>
        <div class="row mb-3 gy-2 justify-content-between">
          <div class="col-md-6 col-lg-4 d-flex">
            <div class="dropdown no-arrow me-2">
              <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bx bx-menu"></i>
              </a>
              <ul class="dropdown-menu">
                <li @click.prevent="toggleFilters()">
                  <button class="dropdown-item" type="button">
                    Toggle Filters
                  </button>
                </li>
                <li @click.prevent="completeProfile()">
                  <button class="dropdown-item" type="button">
                    Complete profile
                  </button>
                </li>
              </ul>
            </div>
            <div class="input-group w-auto">
              <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
              <b-button variant="primary" class="" @click.prevent="popupModalShow = true" type="button">
                <i class="ri ri-add-fill align-bottom"></i> <span class="d-inline"> Create New </span></b-button>
            </div>
          </div>
          <div class="col-md-5">
            <div class="input-group mb-0">
              <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search BVN here..." aria-label="Search here..." aria-describedby="button-search_TB">
              <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
            </div>
          </div>
        </div>
        <div class="overflow-hidden">
          <common-portlet headertitle="Filters"
          :visible="enableFilters" :open="true">
            <div class="loading-viewport">
              <div class="card-body p-lg-3">
                <bank-identity-filter-form 
                @startSearch="setFilters($event)"
                @resetFilters="initializeAll()" />
              </div>
            </div>
          </common-portlet>
        </div>
        <div class="mb-3">
          <p class="mb-0">Showing {{paginationInfo}} results</p>
        </div>
        <div class="card">
          <div class="card-body p-3">
            <div class="table-responsive mb-0 loading-viewport">
              <is-loading v-if="isLoading" :box="true" />
              <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm"  align="middle">
                <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
                  v-slot:[`head(${column.key})`] >
                    <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                    d-flex align-items-center">
                      {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                    </div>
                </template>
                <template v-slot:cell(image)="data">
                <div  class="cursor-pointer">
                  <img @click="showProfileImg(data.item.image)" class="avatar rounded-circle" :src="data.item.image" />
                </div>
              </template>
                <template v-slot:cell(bvn)="data">
                  <div style="min-width:10rem;" class="cursor-pointer">
                    {{ data.item.bvn }}
                  </div>
                </template>
                <template v-slot:cell(first_name)="data">
                  <div style="min-width:8rem;" class="cursor-pointer">
                    {{ data.item.first_name }}
                  </div>
                </template>
                <template v-slot:cell(middle_name)="data">
                  <div style="min-width:9rem;" class="cursor-pointer">
                    {{ data.item.middle_name }}
                  </div>
                </template>
                <template v-slot:cell(last_name)="data">
                  <div style="min-width:8rem;" class="cursor-pointer">
                    {{ data.item.last_name }}
                  </div>
                </template>
                <template v-slot:cell(gender)="data">
                  <div style="min-width:5rem;" class="cursor-pointer">
                    {{ data.item.gender }}
                  </div>
                </template>
                <template v-slot:cell(mobile)="data">
                  <div style="min-width:5rem;" class="cursor-pointer">
                    {{ data.item.mobile }}
                  </div>
                </template>
                <template v-slot:cell(date_of_birth)="data">
                  <div style="min-width:10rem;" class="cursor-pointer">
                    {{ this.$filters.date(data.item.date_of_birth) }}
                  </div>
                </template>
                <template v-slot:cell(religion)="data">
                  <div style="min-width:7rem;" class="cursor-pointer">
                    {{ data.item.religion }}
                  </div>
                </template>
                <template v-slot:cell(complete_profile)="data">
                  <div style="min-width:4rem;" class="cursor-pointer">
                    <span v-if="data.item.complete_profile" class="badge bg-primary font-sm">YES</span>
                    <span v-else class="badge bg-danger font-sm">NO</span>
                  </div>
                </template> 
                <template v-slot:cell(data_validation)="data">
                  <div style="min-width:4rem;" class="cursor-pointer">
                    <span v-if="data.item.data_validation" class="badge bg-primary font-sm">YES</span>
                    <span v-else class="badge bg-danger font-sm">NO</span>
                  </div>
                </template> 
                <template v-slot:cell(selfie_validation)="data">
                  <div style="min-width:4rem;" class="cursor-pointer">
                    <span v-if="data.item.selfie_validation" class="badge bg-primary font-sm">YES</span>
                    <span v-else class="badge bg-danger font-sm">NO</span>
                  </div>
                </template> 
                <template v-slot:cell(all_validation_passed)="data">
                  <div style="min-width:4rem;" class="cursor-pointer">
                    <span v-if="data.item.all_validation_passed" class="badge bg-primary font-sm">YES</span>
                    <span v-else class="badge bg-danger font-sm">NO</span>
                  </div>
                </template> 
                <template v-slot:cell(action)="data">
                  <div class="dropdown dropstart no-arrow">
                    <button class="dropdown-toggle btn btn-sm btn-light" type="button"
                      role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ri-more-2-fill"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <button @click.prevent="deleteItem(data.item.id)" class="dropdown-item"
                          type="button"> Delete </button>
                      </li>
                    </ul>
                  </div>
                </template> 
              </b-table>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import IsLoading from '@/components/IsLoading.vue';
import BankIdentityFilterForm from '@/components/forms/BankIdentityFilterForm.vue';
import CommonPortlet from '@/components/portlets/CommonPortlet.vue';

export default {
  name: "bank-identities",
  components:{
    IsLoading,
    BankIdentityFilterForm,
    CommonPortlet,
  },
  data() {
    return {
      isLoading: false,
      enableFilters: true,
      queryFilters: null,
      searchQuery:"",
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
          key: "image",
          label: "Image",
        },
        {
            key: "bvn",
            label: "BVN",
            sortable: true
        },
        {
            key: "first_name",
            label: "First name",
            sortable: true
        },
        {
            key: "middle_name",
            label: "Middle name",
            sortable: true
        },
        {
            key: "last_name",
            label: "Last name",
            sortable: true
        },
        {
            key: "gender",
            label: "Gender",
            sortable: true
        },
        {
            key: "mobile",
            label: "Mobile",
        },
        {
            key: "date_of_birth",
            label: "Date of Birth",
        },
        {
            key: "religion",
            label: "Religion",
            sortable: true
        },
        {
            key: "complete_profile",
            label: "Complete profile",
            sortable: true
        },
        {
            key: "data_validation",
            label: "Data validation",
            sortable: true
        },
        {
            key: "selfie_validation",
            label: "Selfie validation",
            sortable: true
        },
        {
            key: "all_validation_passed",
            label: "All validation",
            sortable: true
        },
        "action"
      ],
      sortBy: {
        sort_column: "created_at",
        sort_direction: "desc"
      },
      pageData:{
        data: []
      }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 50
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
    getSortingLabel(){
      return key => {
        let iconClass = 'text-muted ri-arrow-up-line';
        if(this.sortBy.sort_column != key) return `<span class='ms-2 ${iconClass}'></span>`;
        iconClass = this.sortBy.sort_direction == 'desc' ? 'ri-arrow-up-line' : 'ri-arrow-down-line';
        return `<span class='ms-2 ${iconClass}'></span>`
      }
    }
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.sortBy
      }
      if(this.searchQuery){ payload.search = this.searchQuery }
      if(this.queryFilters) { Object.assign(payload, this.queryFilters)}
      this.isLoading = true
      this.$http.get(`/bank-identities?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
            this.initializeAll();
          }
        })
    },
    toggleFilters(){
      this.enableFilters = !this.enableFilters; 
      this.initializeAll()
    },
    setFilters(data){
      this.queryFilters = data
      this.fetchItems()
    },
    setSortOrder(column) {
      if (this.sortBy.sort_column === column.key) {
        this.sortBy.sort_direction = this.sortBy.sort_direction === 'desc' ? 'asc' : 'desc';
      } else {
        this.sortBy.sort_column = column.key;
        this.sortBy.sort_direction = 'desc';
      }
      this.fetchItems();
    },
    initializeAll(){
      this.popupModalShow = false;
      this.queryFilters = null;
    },
    showProfileImg(image){
      const data = { index: 0, visible: true, files: [ image] }
      this.$store.commit('TOGGLE_LIGHT_BOX', data)
    },
    deleteItem(itemId) {
      this.confirmDelete({
        text: "You are about to delete a bank's identity",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/bank-identities/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              const itemIndex = this.pageData.data.findIndex((item) => item.id == itemId)
              this.pageData.data.splice(itemIndex, 1)
            }
          })
        }
      });
    },
    completeProfile(){
      this.confirmAction({
        text: "This action will resolve uncompleted profiles from Youverify",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.get('/bank-identities/complete-profile')
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
          })
        }
      });
    }
  },
  mounted() {
  },
}
</script>

