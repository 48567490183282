
<template>
    <form autocomplete="off" class="">
        <is-loading v-if="isLoading" :box="true" />
        <div class="row g-lg-4 g-3">
            <div class="col-md-6 col-lg-4">
                <label class="form-label" for="firstName">First Name</label>
                <input id="firstName" type="text" class="text-uppercase form-control" v-model="data.first_name"
                placeholder="First name" name="first_name" />
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label" for="middleName">Middle Name</label>
                <input id="middleName" type="text" class="text-uppercase form-control" v-model="data.middle_name"
                 placeholder="Middle Name" name="last_name" />
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label" for="LastName">Last Name</label>
                <input id="LastName" type="text" class="text-uppercase form-control" v-model="data.last_name"
                 placeholder="Last Name" name="last_name" />
            </div>
            <div class="col-md-6 col-lg-4">
                <label for="gender" class="form-label">Gender</label>
                <select v-model="data.gender" name="gender" id="gender" class="form-select" >
                <option disabled value="" >--select gender--</option>
                <option v-for="(gender, gdIndex) in genderOptions" :key="gdIndex" 
                    :value="gender.value">{{gender.label}}</option>
                </select>
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label"> Complete Profile</label>
                <select v-model="data.complete_profile" name="complete_profile" class="form-select">
                    <option disabled value=""> --select-- </option>
                    <option v-for="(cpOption, yIndex) in validationOptions" :key="yIndex"
                    :value="cpOption.value">{{cpOption.label}}</option>
                </select>
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label"> Data validation</label>
                <select v-model="data.data_validation" name="data_validation" class="form-select">
                    <option disabled value=""> --select-- </option>
                    <option v-for="(dvOption, yIndex) in validationOptions" :key="yIndex"
                    :value="dvOption.value">{{dvOption.label}}</option>
                </select>
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label"> Selfie validation</label>
                <select v-model="data.selfie_validation" name="selfie_validation" class="form-select">
                    <option disabled value=""> --select-- </option>
                    <option v-for="(svOption, yIndex) in validationOptions" :key="yIndex"
                    :value="svOption.value">{{svOption.label}}</option>
                </select>
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label"> All validation</label>
                <select v-model="data.all_validation_passed" name="all_validation_passed" class="form-select">
                    <option disabled value=""> --select-- </option>
                    <option v-for="(avOption, yIndex) in validationOptions" :key="yIndex"
                    :value="avOption.value">{{avOption.label}}</option>
                </select>
            </div>           
        </div>
        <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>
    
            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
        </div>
    </form>
    </template>
        
    <script>
    import Multiselect from '@vueform/multiselect'
    import IsLoading from '@/components/IsLoading.vue'
    
    export default {
        components: {
            IsLoading,
            Multiselect,
        },
        data() {
            return {
                isLoading: false,
                data: {
                    first_name: "",
                    middle_name: "",
                    last_name: "",
                    gender: "",
                    data_validation: "",
                    selfie_validation: "",
                    complete_profile: "",
                    all_validation_passed: ""
                },
                genderOptions: [
                    { label: 'Male', value: 'Male' },
                    { label: 'Female', value: 'Female' },
                    { label: 'Others', value: 'Others' }
                ],
                validationOptions: [
                    { value: "1", label: "YES"},
                    { value: "0", label: "NO"},
                ],
                mirrorData: null,
            }
        },
        methods: {
            getList() {
                var payload = Object.fromEntries(
                    Object.entries(this.data).filter(([_, v]) => !!v )
                );
                this.$emit('startSearch', payload)
            },
            resetForm() {
                this.data = {...this.mirrorData}
                this.wardOptions = [];
                this.unitOptions = [];
                this.$emit('resetFilters')
            },
            getOptionData(){
               
            },
        },
        mounted(){
            this.getOptionData()
            this.mirrorData = JSON.parse(JSON.stringify(this.data))
        },
    }
    </script>
    